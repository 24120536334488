import { Container, Box, HStack, Tag } from "@chakra-ui/react"

import { ButtonLink, Link } from "@/components/Link"
import Logo from "@/components/Logo"
import { ListIcon } from "@/components/Icon"
import UserDetails from "@/components/TopNav/UserDetails"

const MarketingHeader = () => {
  return (
    <Box position="absolute" top={0} left={0} right={0} zIndex={10}>
      <Container maxW="container.xl">
        <HStack
          h="4rem"
          spacing="lg"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Link href="/" display="inline-block">
              <Logo />
            </Link>
            <Tag
              colorScheme="brand"
              ml="0.5rem"
              size="sm"
              fontWeight={700}
              borderRadius="sm"
            >
              {"BETA"}
            </Tag>
          </Box>
          <HStack spacing="xs">
            <ButtonLink
              href="/datasets"
              leftIcon={<ListIcon size="0.875rem" />}
              variant="navGhost"
              size="sm"
            >
              {"My datasets"}
            </ButtonLink>
            <UserDetails />
          </HStack>
        </HStack>
      </Container>
    </Box>
  )
}

export default MarketingHeader
