import { useState } from "react"
import {
  Stack,
  Container, 
  Heading,
  Box,
  Image,
  Center, 
  Flex, 
  UnorderedList,
  ListItem
} from "@chakra-ui/react"
import { ButtonLink } from "@/components/Link"

const Steps = () => {
  const stepsData = [
    `Click “Get started”, no sign-in is required.`,
    `Upload your own data or choose one of the sample ones we offer.`,
    `Within the preview, edit any column header to help us analyze your data better.`,
    `Start your journey by either choosing from a list of suggested combinations, or making your own custom chart.`,
    `Add extra details (like columns, values or preferences) that we might suggest to improve your visual model. Depending on your data visualization model, we’ll help you find the best way to display your data.`,
    `Get your visual insights!  And export them in SVG or PNG format and share your valuable insights with the world!`
  ]

  return (
    <Container maxW="container.xl" my="4rem">
      <Stack spacing="4rem">
        <Flex wrap="wrap" justifyContent="space-between">

        <Center w={[null, null, "100%", "48.5%"]}>
          <Stack>
            <Heading as="h2" variant="stepTitle">{"How to Start Analyzing Data in 6 Simple Steps"}</Heading>
            <Box pt="2">
              <UnorderedList>
                {stepsData.map((step, key) => (
                  <ListItem key={key} py="1">{step}</ListItem>
                ))}
              </UnorderedList>
            </Box>
          </Stack>
          </Center>
          <Center w={[null, null, "100%", "48.5%"]} mt={["10", "10", "10", "0"]}>
            <Image 
              alt="Vizdium editor" 
              src="/images/vizdium-editor.webp" 
              fallbackSrc="/images/vizdium-editor.png" 
              w="100%" 
              borderRadius="md" 
              boxShadow='2xl'
            />
          </Center>
        </Flex>

        <Center>
          <ButtonLink
            href={"/new-dataset"}
            size="lg"
            fontSize="1.125rem"
            colorScheme="brand"
            borderRadius="full"
            mr={["0", "sm"]}
            mb={["sm", "0"]}
            w={["100%", "auto"]}
          >
            {"Get started"}
          </ButtonLink>
        </Center>    
      </Stack>
    </Container>
  )
}

export default Steps
