import { useState } from "react"
import {
  Stack,
  Container, 
  Heading,
  Text,
  Box,
  Image,
  Center, 
  Flex
} from "@chakra-ui/react"
import { ButtonLink } from "@/components/Link"

const Features = () => {

  const featuresData = [
    {
      icon: "/images/chat.png",
      title: "Smart Guides",
      text: "Explore your data with the help of an AI-enabled smart assistant. We'll guide you step by step from data to insights. "
    },
    {
      icon: "/images/charts.png",
      title: "Charts and Graphs",
      text: "Explore a variety of charts and visual models to export your data. Visualize as a barplot, heat map, scatter plot, histogram and more."
    },
    {
      icon: "/images/download.png",
      title: "Download & Share",
      text: "Download your data insights and charts, or export them to other applications. No sign-up or payment required."
    }
  ]

  return (
    <Container maxW="container.xl" my="4rem">
      <Stack spacing="4rem">
        <Stack textAlign="center" alignItems="center" spacing="1.5rem">
          <Heading as="h2" variant="subTitle">{"Intuitive data visualization software"}</Heading>
          <Text fontSize="lg" maxW="33rem">
            {
              "Deliver value through your communications with our modern and simple features."
            }
          </Text>
        </Stack>
        <Flex wrap="wrap"  justifyContent="center"> 
          {featuresData.map((feature) => ( 
            <Box w={["100%", null, "50%", "33%"]} key={feature.title}>
              <Stack>
                <Center>
                  <Image alt={feature.title} src={feature.icon} h="10rem" w="10rem" />
                </Center>
                <Text textAlign="center" fontWeight="bold" fontSize='lg'>{feature.title}</Text>
                <Box mt="72">
                  <Text textAlign="center" w="80%" mx="auto" >{feature.text}</Text>
                </Box>
              </Stack>
            </Box>
          ))}
        </Flex>
        <Center>
          <ButtonLink
            href={"/new-dataset"}
            size="lg"
            fontSize="1.125rem"
            colorScheme="brand"
            borderRadius="full"
            mr={["0", "sm"]}
            mb={["sm", "0"]}
            w={["100%", "auto"]}
          >
            {"Get started"}
          </ButtonLink>
        </Center>    
      </Stack>
    </Container>
  )
}

export default Features
