import {
  Container,
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  Flex,
  SimpleGrid,
  Tag,
  Image,
} from "@chakra-ui/react"

import { ButtonLink, Link } from "@/components/Link"
import MarketingHeader from "@/components/MarketingHeader"
import { ChevronRightIcon } from "@/components/Icon"
import Features from "./Features"
import Steps from "./Steps"
import Faq from "./Faq"

const LandingPage = ({ datasets }) => {
  return (
    <>
      <MarketingHeader />

      <Stack
        bg="white"
        alignItems="center"
        spacing={["4rem", null, "7rem", "9rem"]}
      >
        <Box overflow="hidden" w="100%">
          <Container maxW="container.xl">
            <SimpleGrid columns={8} gridGap={["1.25rem", null, null, "2.5rem"]}>
              <Box
                gridColumn={["1 / -1", null, null, "2 / -2", "1 / 4"]}
                gridRow="1"
                alignSelf="center"
              >
                <Stack
                  spacing="2rem"
                  pt={["6rem", null, null, null, "0"]}
                  pb={["2.5rem", null, null, null, "0"]}
                >
                  <HStack>
                    <Heading as="h3" variant="heroInfo">{"Brought to you by "}</Heading>
                    <Link href="https://venngage.com/">
                      <Image alt="Venngage’s Infographic Maker" src="/venngage_logo.svg" h="1.25rem" />
                    </Link>
                  </HStack>
                  <Heading as="h1" variant="heroTitle" maxW="28rem">
                    {"The Quick and Easy Free Data Visualization Tool"}
                  </Heading>
                  <Heading as="h2" variant="heroSubtitle">
                    {
                      "Identify trends, reinforce an argument, tell better stories with engaging and easily digestible data! No data analysis experience required."
                    }
                  </Heading>
                  <Flex alignItems="center" flexWrap="wrap">
                    <ButtonLink
                      href={"/new-dataset"}
                      size="lg"
                      fontSize="1.125rem"
                      colorScheme="brand"
                      borderRadius="full"
                      mr={["0", "sm"]}
                      mb={["sm", "0"]}
                      w={["100%", "auto"]}
                    >
                      {"Get started"}
                    </ButtonLink>
                    <ButtonLink
                      href="#sample-data"
                      variant="ghost"
                      size="lg"
                      fontSize="1.125rem"
                      pr="md"
                      rightIcon={<ChevronRightIcon size="20" />}
                      w={["100%", "auto"]}
                    >
                      {"Try a sample dataset"}
                    </ButtonLink>
                  </Flex>
                </Stack>
              </Box>
              <Box
                gridColumn="-5 / -1"
                gridRow="1"
                display={["none", null, null, null, "block"]}
              >
                <Box
                  w="50vw"
                  h="100%"
                  bg="Background.500"
                  bgGradient="linear(to-tl, #8282E3, brand.500)"
                />
              </Box>
              <Box
                gridColumn={["1 / -1", null, null, null, "-6 / -1"]}
                gridRow={["2", null, null, null, "1"]}
                px={["0", null, null, "5rem", "0"]}
                pt={["0", null, null, null, "6rem"]}
                pb={["2.5rem", null, null, null, "5rem"]}
              >
                <Image
                  src="/hero_image.png"
                  boxShadow="0 0.125rem 0.25rem rgba(0, 0, 0, 0.1), 0 1rem 2.5rem rgba(0, 0, 0, 0.05) "
                  borderRadius="md"
                />
              </Box>
            </SimpleGrid>
          </Container>
        </Box>

        <Container id="sample-data" maxW="container.xl" my="4rem">
          <Stack spacing="4rem">
            <Stack textAlign="center" alignItems="center" spacing="1.5rem">
              <Heading as="h2" variant="subTitle">{"Unlock data insights for free"}</Heading>
              <Text fontSize="lg" maxW="33rem">
                {
                  "Information is power. Get started with one of our sample datasets and see how Vizdium can work for you."
                }
              </Text>
            </Stack>
            <SimpleGrid
              columns={[1, null, 2, 3]}
              gridGap={["1.25rem", null, null, "2.5rem"]}
            >
              {datasets.map(({ id, title, field, numRows, numColumns }) => {
                return (
                  <Link
                    key={id}
                    href={`/new-dataset?sample_id=${id}`}
                    variant="cardLink"
                    minH="9.625rem"
                  >
                    <Stack
                      spacing="1.25rem"
                      px="1.25rem"
                      py="1.25rem"
                      borderRadius="md"
                      h="100%"
                    >
                      <Stack spacing="0.5rem" h="100%">
                        <Heading fontSize="lg">{title}</Heading>
                        <Text
                          fontSize="sm"
                          color="gray.600"
                          fontWeight="600"
                          lineHeight="shorter"
                        >
                          {`${numRows} rows • ${numColumns} columns`}
                        </Text>
                      </Stack>
                      <Tag
                        colorScheme="brand"
                        size="sm"
                        w="fit-content"
                        fontWeight={700}
                        borderRadius="sm"
                        textTransform="uppercase"
                        letterSpacing="0.03125rem"
                      >
                        {field}
                      </Tag>
                    </Stack>
                  </Link>
                )
              })}
              <Link
                href={"/new-dataset"}
                variant="cardLink"
                display="flex"
                alignItems="center"
                justifyContent="center"
                minH="9.625rem"
                bgGradient="linear(to-br, blue.500, purple.500)"
              >
                <HStack
                  spacing="xs"
                  color="white"
                  px="1.25rem"
                  py="1.25rem"
                  borderRadius="md"
                  h="100%"
                >
                  <Text fontWeight="600" fontSize="lg">
                    {"Analyze your own dataset"}
                  </Text>
                  <ChevronRightIcon />
                </HStack>
              </Link>
            </SimpleGrid>
          </Stack>
        </Container>

        <Features />
        <Steps />
        <Faq />

        <Box bg="gray.900" color="white" py="7rem" w="100%">
          <Container maxW="55rem">
            <Stack alignItems="center" textAlign="center" spacing="2.5rem">
              <Heading as="h2" variant="footerTitle">
                {"Don’t know where to start analyzing your data? Let Vizdium make it easy for you!"}
              </Heading>
              <ButtonLink
                href={"/new-dataset"}
                size="lg"
                fontSize="1.125rem"
                colorScheme="brand"
                borderRadius="full"
                mr={["0", "sm"]}
                mb={["sm", "0"]}
                w={["100%", "auto"]}
              >
                {"Get started for free"}
              </ButtonLink>
            </Stack>
          </Container>
        </Box>
      </Stack>
      <Box
        bg="gray.900"
        color="white"
        py={["1.25rem", null, "2.5rem"]}
        borderTop="0.0625rem solid"
        borderColor="#272A30"
      >
        <Container maxW="container.xl">
          <HStack justifyContent="center">
            <Text variant="footerText" textAlign="center">
              {`© ${new Date().getFullYear()} Vizdium`}
            </Text>
          </HStack>
        </Container>
      </Box>
    </>
  )
}

export default LandingPage
