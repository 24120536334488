import Head from "next/head"

import SEO from "@/components/SEO"
import LandingPage from "@/components/pages/LandingPage"
import { SAMPLE_DATASETS } from "@/lib/storage"

import { getMixpanel } from "@/lib/mixpanel"

const Index = ({ datasets }) => {
  getMixpanel()?.track("Visit app landing page")

  return (
    <>
      <SEO 
        title="Free Data Visualization Tool | Discover Valuable Data Insights" 
        meta_description="Understand your data in minutes with Vizdium, the simple free data visualization tool. 
          Simply drag and drop your CSV file and get a digestible data analysis. No coding or sign-up required." 
      />
      <LandingPage datasets={datasets} />
    </>
  )
}

export async function getStaticProps() {
  return {
    props: { datasets: SAMPLE_DATASETS },
  }
}

export default Index
