import Head from "next/head"

const SEO = ({ title = "Vizdium", meta_description = "Understand your data in minutes with Vizdium" }) => {
  return (
    <Head>
      <title>{title}</title>
      <link rel="icon" href="/favicon.ico" />
      <meta name="description" content={meta_description} />
      
    </Head>
  )
}

export default SEO
