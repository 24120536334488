import { useState } from "react"
import {
  Stack,
  Container, 
  Heading,
  Text,
  Box,
  Center, 
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionPanel, 
  AccordionButton
} from "@chakra-ui/react"
import { ButtonLink } from "@/components/Link"

const Faq = () => {
  const faqData = [
    {
      title: "Is Vizdium Free?",
      body: "Yes. It’s a completely free tool. No sign-up is required at all and downloads are also fully free. We won’t store your information, so if you don’t download your charts you’ll have to start over again."
    },
    {
      title: "Does Vizdium save my information?",
      body: "No, we don’t. Data is only stored on your browser’s session memory. If you upload your file and end the session your file will no longer be accessible either for you or for Vizdum. No sign-up required comes with the freedom of not getting anything from you! This is a fully free tool, nothing is required from us. If you want to should your gratitude just mention us and say thanks!"
    },
    {
      title: "I need support, who can I reach out to?",
      body: "If you need help simply email us at info@venngage.com and we’ll make sure to provide the best support possible!"
    },
    {
      title: "What is data visualization?",
      body: "Data visualization is the visual presentation of data or information. When analyzing big data, your goal is to explore, monitor and explain; the goal of data visualization is to communicate that raw information into valuable actionable insights, in a clear and effective way. Data visualization combines art and data science. While data visualization can be creative and pleasing to look at, it should also be functional in its visual communication of the data. "
    },
    {
      title: "What are the types of data visualization?",
      body: "There are many types of data visualization. Typically, data is visualized in the form of scatter plots, line graphs, pie charts, bar charts, heat maps, area charts, choropleth maps and histograms. Vizdium’s data visualization software offers a variety of visualization models to leverage data in an easy but powerful way."
    },
    {
      title: "Which tool is the best for data visualization?",
      body: "Even though there are many data visualization tools available online, Vizdium is the free data visualization tool of choice. It has an intuitive interface, it provides graphical representation in just a few clicks and it’s free to use! Everything a business intelligence tool would do, we do it — easier and faster."
    }
  ]

  const openAccordion = faqData.map((data, key) => key);

  return (
    <Container maxW="container.xl" my="4rem">
      <Stack spacing="4rem">
        <Center>
          <Heading as="h2" variant="subTitle">{"FAQs"}</Heading>
        </Center>

        <Box maxW="100%">
          <Accordion maxW="container.md" mx="auto" defaultIndex={openAccordion} allowMultiple>
            {faqData.map(faq => (
              <AccordionItem key={faq.title}>
                <h3>
                  <AccordionButton>
                    <Box flex='1' textAlign='left'>
                      <Text variant="accordionTitle">{faq.title}</Text>
                    </Box>
                    <AccordionIcon color="#0082FF" w="1.65rem" h="1.65rem" />
                  </AccordionButton>
                </h3>
                <AccordionPanel pb={6}>
                  <Text variant="accordionBody">{faq.body}</Text>
                </AccordionPanel>
              </AccordionItem>
            ))}

          </Accordion>
        </Box>
        

        <Center>
          <ButtonLink
            href={"/new-dataset"}
            size="lg"
            fontSize="1.125rem"
            colorScheme="brand"
            borderRadius="full"
            mr={["0", "sm"]}
            mb={["sm", "0"]}
            w={["100%", "auto"]}
          >
            {"Get started"}
          </ButtonLink>
        </Center>    
      </Stack>
    </Container>
  )
}

export default Faq
