import mixpanel from "mixpanel-browser"

const MIXPANEL_KEY = process.env.NEXT_PUBLIC_MIXPANEL_KEY

function getMixpanel() {
  if (!mixpanel.vizdium) {
    if (MIXPANEL_KEY) {
      console.debug("Initializing Mixpanel")
      mixpanel.init(MIXPANEL_KEY, {}, "vizdium")
      return mixpanel.vizdium
    } else {
      return null
    }
  } else {
    return mixpanel.vizdium
  }
}

export { getMixpanel }
